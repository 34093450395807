// ------------------------------------------------------
//  Handles newsletter widget form submission
// ------------------------------------------------------
jQuery(document).ready(function($) {

	var cbt_wn = {
	
		prepare: function(newletter_instance)
		{
			//var $form = $(form);
			var $name  = $('.cbt-newsletter-field-first-name', newletter_instance);
			var $last_name  = $('.cbt-newsletter-field-last-name', newletter_instance);
			var $email = $('.cbt-newsletter-field-email');
			var name  = $name .val().replace('"', '').replace('\'', '&#39;');
			var last_name  = $last_name .val().replace('"', '').replace('\'', '&#39;');
			var email = $email.val().replace('"', '').replace('\'', '&#39;');

			var full_name = name + ' ' + last_name;

			var $real_form = $('.hidden_autoresponder_form form', newletter_instance).clone();
			var html = $real_form.html();
			
			if (!html) return true;
			
			var new_html = html.replace('[@#POPNAME#@]', full_name);
			
			if (html == new_html) 
			{ // Name not replaced
				html = html.replace('[@#POPFIRSTNAME#@]', name);
				html = html.replace('[@#POPLASTNAME#@]', last_name);
			} else { // Name replaced
				html = new_html;
			}
			
			html = html.replace('[@#POPEMAIL#@]', email);

			$real_form.html(html);
			$real_form.attr('target', '_blank');
			cbt_wn.submit_form($real_form);
	
			return false;
		},
		
		submit_form: function (form)
		{
			form = $(form).appendTo(document.body);
			try {
				HTMLFormElement.prototype.submit.apply(form);
			} catch (ex1) {
				try {
					form.submit();
				} catch (ex2) {}
			}
		}
	}

	$('form.cbt-newsletter-form').submit(function()
	{
		var newletter_instance = $(this).parent();
		cbt_wn.prepare(newletter_instance);
		return false;
	});

});